import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('customer', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getCustomerDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customerDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listCategorySale() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listCategorySale')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    listCategoryPurchase() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listCategoryPurchase')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    getAdjustmentItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listAdjustmentItem',  { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    getItemDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listItem')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryDropAll')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('inventoryReportData', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    businessOverviewSale(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('businessOverviewSale', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    businessOverviewInventory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('businessOverviewInventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    fetchExpense() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listExpense')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchAdjustment() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listAdjustment')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchExpenseById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showExpense/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deletePurchaseFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deletePurchaseFile/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    filterPeriod(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('filterInventoryPeriod', queryParams)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    pdfdownloadPurchaseOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadPurchaseOrder', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfdownloadSaleOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadSaleOrder', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    pdfdownloadAdjustment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadAdjustment', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchIncome() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listIncome')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    fetchIncomeById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showIncome/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    fetchAdjustmentById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showAdjustment/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomerById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customer/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    savePurchaseOrder(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveExpense', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    saveSaleOrder(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveIncome', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveAdjustment(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveAdjustment', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },


    saveCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('customer', customerData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    savePayment(ctx, paymentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('savePayment', paymentData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveItem', { productName: itemData.val, categoryId: itemData.categoryId })
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveAdjustmentItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveAdjustmentItem', { productName: itemData.val, type: itemData.type })
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    

    saveCategory(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCategory',  itemData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`customer/${customerData.id}`, customerData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`expenseStatus`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    incomeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`incomeStatus`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    adjustmentStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`adjustmentStatus`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },


    updateExpense(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateExpense`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateAdjustment(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateAdjustment`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateIncome(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateIncome`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchSaleOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('saleorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customer_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchAdjustmentColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('adjustment_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


  },
};
