<template>
  <b-sidebar id="sidebar-invoice-add-new-customer" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Customer
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- Body -->
      <validation-observer ref="customerRules">
        <b-form class="p-2" @submit.prevent v-if="customer.sellerType==1">
          <!-- Customer Name -->

          <b-form-radio-group class="border-bottom pb-1">
            <validation-provider name="Customer Type" #default="{ errors }" rules="required">

              <b-form-radio v-model="customer.sellerType" name="seller-type" class="mr-2 " value="1">
                Individual
              </b-form-radio>
              <b-form-radio v-model="customer.sellerType" name="seller-type" class="mr-2 " value="2">
                Business
              </b-form-radio>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-radio-group>



          <b-form-group label="First Name" label-for="customer-name">
            <validation-provider name="First Name" #default="{ errors }" rules="required">
              <b-form-input id="first-name" v-model="customer.firstName" trim />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Last Name" label-for="customer-name">
            <b-form-input id="last-name" v-model="customer.lastName" trim />
          </b-form-group>

          <!-- Email -->
          <b-form-group label="Email" label-for="email">
            <validation-provider name="Email" #default="{ errors }" rules="email|required">
              <b-form-input id="email" v-model="customer.email" trim type="email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Phone -->
          <b-form-group label="Phone" label-for="contact">
            <validation-provider name="Phone Number" #default="{ errors }" rules="required">
              <b-form-input id="contact" v-model="customer.phone" type="number" trim />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Address -->
          <b-form-group label="Street Address" label-for="address">
            <validation-provider name="Street Address" #default="{ errors }" rules="required">
              <b-form-textarea id="address" v-model="customer.street" trim />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Country -->
          <b-form-group label="City" label-for="city">
            <validation-provider name="City" #default="{ errors }" rules="required">
              <b-form-input id="city" v-model="customer.city" :clearable="false" input-id="city" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Province" label-for="province">
            <validation-provider name="Provence" #default="{ errors }" rules="required">
              <b-form-input id="province" v-model="customer.province" :clearable="false" input-id="province" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Postal Code" label-for="postalCode">
            <validation-provider name="Postal Code" #default="{ errors }" rules="required">
              <b-form-input id="postalCode" v-model="customer.postalCode" :clearable="false" input-id="postalCode" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Country" label-for="country">
            <validation-provider name="Country" #default="{ errors }" rules="required">
              <b-form-input id="country" v-model="customer.country" :clearable="false" input-id="country" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" @click="add">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="cancel">
              Cancel
            </b-button>
          </div>
        </b-form>

        <b-form class="p-2" @submit.prevent v-if="customer.sellerType==2">



          <b-form-radio-group class="border-bottom pb-1">
            <validation-provider name="Customer Type" #default="{ errors }" rules="required">

              <b-form-radio v-model="customer.sellerType" name="seller-type" class="mr-2 " value="1">
                Individual
              </b-form-radio>
              <b-form-radio v-model="customer.sellerType" name="seller-type" class="mr-2 " value="2">
                Business
              </b-form-radio>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-radio-group>


          <!-- Customer Name -->
          <b-form-group label="Business Name" label-for="customer-name">
            <validation-provider name="First Name" #default="{ errors }" rules="required">
              <b-form-input id="first-name" v-model="customer.firstName" trim />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Operating Name" label-for="customer-name">
            <b-form-input id="last-name" v-model="customer.lastName" trim />
          </b-form-group>

    
          <b-form-group label="Contact Person" label-for="customer-contact-person">
            <b-form-input id="c-person" v-model="customer.contactPerson" trim />
          </b-form-group>
 
          <b-form-group label="Contact Info" label-for="customer-contact-info">
            <b-form-input id="c-info" v-model="customer.contactInfo" trim />
          </b-form-group>


          <!-- Email -->
          <b-form-group label="Email" label-for="email">
            <validation-provider name="Email" #default="{ errors }" rules="email|required">
              <b-form-input id="email" v-model="customer.email" trim type="email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Phone -->
          <b-form-group label="Phone" label-for="contact">
            <validation-provider name="Phone Number" #default="{ errors }" rules="required">
              <b-form-input id="contact" v-model="customer.phone" type="number" trim />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Address -->
          <b-form-group label="Street Address" label-for="address">
            <validation-provider name="Street Address" #default="{ errors }" rules="required">
              <b-form-textarea id="address" v-model="customer.street" trim />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Country -->
          <b-form-group label="City" label-for="city">
            <validation-provider name="City" #default="{ errors }" rules="required">
              <b-form-input id="city" v-model="customer.city" :clearable="false" input-id="city" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Province" label-for="province">
            <validation-provider name="Provence" #default="{ errors }" rules="required">
              <b-form-input id="province" v-model="customer.province" :clearable="false" input-id="province" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Postal Code" label-for="postalCode">
            <validation-provider name="Postal Code" #default="{ errors }" rules="required">
              <b-form-input id="postalCode" v-model="customer.postalCode" :clearable="false" input-id="postalCode" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Country" label-for="country">
            <validation-provider name="Country" #default="{ errors }" rules="required">
              <b-form-input id="country" v-model="customer.country" :clearable="false" input-id="country" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" @click="add">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="cancel">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import accountingStoreModule from '../accountingStoreModule';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    dropCustomers: {
      type: Array,
      required: false,
    },

    invoiceData: {
      type: Object,
      required: false,
    },
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    return {
      customer: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        streetAddress: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        sellerType: 1,
        contactPerson : '',
        contactInfo : '',
      },
      required,
      email,
    };
  },
  methods: {
    add() {
      this.$refs.customerRules.validate().then((success) => {
        if (success) {
          store.dispatch('accounting/saveCustomer', this.customer).then((response) => {
            if (response.status == 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Customer Insert Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.customer = {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                streetAddress: '',
                city: '',
                province: '',
                postalCode: '',
                country: '',
                sellerType: 1,
                contactPerson : '',
                contactInfo : '',
              };
              var tempData = this.dropCustomers;
              this.tempInvoiceData = this.invoiceData;
              this.tempInvoiceData.customerId = response.data.id;

              tempData.unshift({ id: response.data.id, value: '#' + String(response.data.id) + '- ' + String(response.data.firstName) + ' ' + String(response.data.lastName) });
              this.$emit('update:dropCustomers', tempData);

              this.$emit('update:invoiceData', this.tempInvoiceData);

              this.$root.$emit('bv::toggle::collapse', 'sidebar-invoice-add-new-customer');

              setTimeout(function() {}, 1000);
            }
          });
        }
      });
    },
    cancel() {
      this.customer = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        streetAddress: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        sellerType: 1,
        contactPerson : '',
        contactInfo : '',
      };
      this.$refs.customerRules.reset();

      this.$root.$emit('bv::toggle::collapse', 'sidebar-invoice-add-new-customer');
    },
  },

  directives: {
    Ripple,
  },


  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
